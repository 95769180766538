import { ProductBadges } from "@components/ui/product-badges/ProductBadges";
import type { CustomBadgeWithProducts } from "@components/ui/product-badges/types";
import { badgesMap } from "@components/ui/product-badges/types";
import { getCustomBadgeBySkus } from "@components/ui/product-badges/utils";
import { getSiteSettings } from "@config/site/site-api-settings";
import type { Document } from "@contentful/rich-text-types";
import type { AlgoliaItem } from "@lib/analytics/analyticsTypes";
import { AnalyticsClient } from "@lib/analytics/AnalyticsClient";
import { CommonCMS } from "@lib/constants/contentful";
import { pluralizeHelper, useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { hasOneSize } from "@lib/utils/productUtility";
import {
    addToMyShoppingList,
    createMyShoppingList,
    removeFromMyShoppingList,
    showErrorToaster,
    signInAndGetSession,
} from "@lib/utils/shoppingList-utility/shoppingListUtility";
import { useErrorToast } from "@lib/utils/store-utility/storeUtility";
import { useCreateMyWishlist, useGetMyActiveShoppingList } from "@store/wishlist/wishlistHooks";
import {
    useAddProductAndRemoveOldestFromWishlist,
    useAddProductToWishlist,
    useRemoveProductFromWishlist,
} from "@store/wishlist/wishlistProductHooks";
import type { Variant } from "@components/commerce/product/product-tile/ProductTile";
import { ProductTile } from "@components/commerce/product/product-tile/ProductTile";
import { Box } from "@ui/components/layout/box/Box";
import { Carousel } from "@ui/components/navigation/carousel/Carousel";
import { isEmpty } from "lodash-es";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import type { FC, ReactNode } from "react";
import { useEffect, useState } from "react";
import type { StoreState } from "store";
import { useLock, useStore } from "store";
import dynamic from "next/dynamic";

const AddedToWishlistModal = dynamic(
    () => import("@components/ui/modals/add-to-wishlist-modal/AddedToWishlistModal")
);

type ProductCarouselProps = {
    slideData: object[];
    assistiveMessage: string;
    headline: string;
    slidesPerView?: number[];
    carouselType?: string;
    authorizationInvitation?: Document;
    extraLink?: ReactNode;
    id?: string;
};

export const ProductCarousel: FC<ProductCarouselProps> = (props) => {
    const {
        slideData,
        slidesPerView = [1.3, 2.5, 3.5],
        headline,
        assistiveMessage,
        carouselType,
        authorizationInvitation,
        extraLink = null,
        id,
    } = props;

    const { data: session } = useSession();
    const { locale } = useRouter();
    const errorToaster = useErrorToast();
    const {
        setWishlistIsExecuted,
        setAlgoliaQueryID,
        data: {
            me: {
                wishlistInfo: { wishlist },
            },
        },
    } = useStore<StoreState>();
    const lock = useLock();
    const { get: getMicrocopy, getMultiple: getMicrocopies } = useMicrocopy();
    const [cachedSkus, setCachedSkus] = useState<string[]>([]);

    const [isOpenAddedToWishlistModal, setIsOpenAddedToWishlistModal] = useState<boolean>(false);
    const [selectedHitId, setSelectedHitId] = useState<string>();
    const [customBadge, setCustomBadge] = useState<CustomBadgeWithProducts>();

    const badgesMicrocopy = getMicrocopies(
        CommonCMS.page,
        badgesMap.map((b) => b.name)
    );

    const executeCreateMyWishlist = useCreateMyWishlist();
    const executeGetMyActiveWishlist = useGetMyActiveShoppingList();
    const executeAddProductToWishlist = useAddProductToWishlist();
    const executeAddProductAndRemoveOldestFromWishlist = useAddProductAndRemoveOldestFromWishlist();
    const executeRemoveProductFromWishlist = useRemoveProductFromWishlist();

    const handleAddProductToWishlist = lock(async (product, hit) => {
        setWishlistIsExecuted(false);
        setIsOpenAddedToWishlistModal(true);
        await signInAndGetSession(session, getMicrocopy, errorToaster);

        await executeGetMyActiveWishlist({});
        if (!wishlist) {
            await createMyShoppingList(locale, getMicrocopy, executeCreateMyWishlist, errorToaster);
        }

        try {
            await addProductToWishlist(product, hit);
        } catch (error) {
            showErrorToaster(getMicrocopy, errorToaster);
        }
        setWishlistIsExecuted(true);
    });

    const addProductToWishlist = async (product, hit) => {
        const foundItem = wishlist?.lineItems?.find(
            (x) =>
                x.variant?.sku === product.objectID ||
                product.variants?.some((v: Variant) => v.sku === x.variant?.sku)
        );
        const sku =
            product.variants?.find((v: Variant) => hasOneSize(v.size))?.sku ||
            foundItem?.variant?.sku ||
            product.objectID;

        setSelectedHitId(sku);

        if (foundItem) {
            await Promise.all([
                removeFromMyShoppingList(
                    locale,
                    foundItem,
                    wishlist,
                    executeRemoveProductFromWishlist
                ),
                AnalyticsClient.removeFromWishList(hit),
            ]);
        } else {
            await Promise.all([
                AnalyticsClient.addToWishList(hit),
                addToMyShoppingList(
                    locale,
                    wishlist,
                    sku,
                    executeAddProductToWishlist,
                    executeAddProductAndRemoveOldestFromWishlist
                ),
            ]);
        }
    };

    useEffect(() => {
        if (!slideData.length) {
            return;
        }

        const skus = slideData.map((slide) => slide.toString());
        const skusChanged = JSON.stringify(skus) !== JSON.stringify(cachedSkus);
        if (!skusChanged) {
            return;
        }

        setCachedSkus(skus);

        (async () => {
            const { badge, products } = await getCustomBadgeBySkus({
                skus,
                locale,
                localeTagId: getSiteSettings.contentfulLocaleTagId,
            });

            if (badge && products.length) {
                setCustomBadge({
                    badge,
                    products,
                });
            }
        })();
        return () => setCustomBadge({} as unknown as CustomBadgeWithProducts);
    }, [slideData, locale]);

    const [intersectItems, setIntersectItems] = useState(new Set());

    useEffect(() => {
        let timeoutId;
        if (intersectItems.size) {
            timeoutId = setTimeout(() => {
                AnalyticsClient.viewItemsList(
                    (slideData as AlgoliaItem[]).filter((item) =>
                        intersectItems.has(item.objectID)
                    ),
                    { listName: carouselType }
                );
                setIntersectItems(new Set());
            }, 2000);
        }
        return () => clearTimeout(timeoutId);
    }, [intersectItems]);

    if (slideData.length === 0) {
        return null;
    }

    return (
        <Box
            w="full"
            mt="3"
            data-testid="product-carousel"
            data-contentful-entry-id={id}
            data-contentful-field-id={"moduleCustomProductCarousel"}
        >
            <Carousel
                title={headline}
                titleAriaLabel={assistiveMessage}
                showNavigation={false}
                slidesPerView={slidesPerView}
                gap={1}
                px={[0, 0, 0, 0]}
                py={[2, 2, 2, 2]}
                extraNavigationButtons={extraLink}
                h={["125vw", "100vw", "66vw", "47vw", "45vw"]}
            >
                {slideData?.map((slide: any) => {
                    if (!slide) {
                        return null;
                    }
                    const hasStockCount = (slide.productColors || []).filter(
                        (color) => color.hasStock === true
                    ).length;

                    const colorMicrocopy = `${hasStockCount + 1} ${getMicrocopy(
                        CommonCMS.global,
                        CommonCMS.colour,
                        {
                            pluralize: pluralizeHelper(hasStockCount + 1),
                        }
                    )}`;

                    const foundItem = wishlist?.lineItems?.find(
                        (x) =>
                            x.variant?.sku === slide.objectID ||
                            slide.variants?.some((v: Variant) => v.sku === x.variant?.sku)
                    );
                    return (
                        <Box
                            maxW={[
                                "calc(100vw/1.3)",
                                "calc(100vw/2.5)",
                                "calc(100vw/2.5)",
                                "calc(100vw/3.5)",
                            ]}
                            w={"100%"}
                            key={slide.objectID}
                        >
                            <ProductTile
                                key={slide.objectID}
                                productName={slide.productName}
                                variants={slide.variants}
                                articleNumber={slide.articleNumber}
                                colorCode={slide.colorCode}
                                image={slide.image}
                                imageLabel={slide.imageLabel}
                                altTagPattern={`${slide.pageTitle ?? slide.productName} - ${slide.dominantColor}`}
                                objectID={slide.objectID}
                                isAddingToWishlist={Boolean(foundItem)}
                                onAddToWishlist={(product) =>
                                    handleAddProductToWishlist(product, slide)
                                }
                                priceWithDiscount={slide.priceWithDiscount}
                                priceWithoutDiscount={slide.priceWithoutDiscount}
                                discountPercentage={slide.discountPercentage}
                                imageBackgroundColor={slide.imageBackgroundColor}
                                productColors={slide.productColors}
                                colorLabel={colorMicrocopy}
                                locale={locale}
                                backgroundColor={slide.backgroundColor}
                                handleTileClick={() => {
                                    setAlgoliaQueryID(null);
                                    AnalyticsClient.selectItem(slide, {
                                        position: slide.index,
                                        listName: carouselType,
                                    });
                                }}
                                badges={slide.badges}
                                descriptiveHeading={slide.descriptiveHeading}
                                tagComponent={
                                    <ProductBadges
                                        badgesMicrocopy={
                                            isEmpty(customBadge)
                                                ? badgesMicrocopy
                                                : {
                                                      ...badgesMicrocopy,
                                                      [customBadge?.badge?.fields?.key]:
                                                          customBadge?.badge?.fields?.value,
                                                  }
                                        }
                                        productBadge={
                                            !isEmpty(customBadge) &&
                                            customBadge.products.includes(
                                                slide.articleNumber + slide.colorCode
                                            )
                                                ? customBadge?.badge
                                                : undefined
                                        }
                                    />
                                }
                                w="full"
                                onInViewPort={(inView, item) => {
                                    setIntersectItems((items) => {
                                        if (inView) {
                                            items.add(item);
                                        } else {
                                            items.delete(item);
                                        }
                                        return new Set(items);
                                    });
                                }}
                                hasStock={slide.hasStock}
                            />
                        </Box>
                    );
                })}
            </Carousel>
            {isOpenAddedToWishlistModal && (
                <AddedToWishlistModal
                    isOpen={isOpenAddedToWishlistModal}
                    onClose={() => setIsOpenAddedToWishlistModal(false)}
                    selectedProductSku={selectedHitId}
                    authorizationInvitation={authorizationInvitation}
                />
            )}
        </Box>
    );
};
